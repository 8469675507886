import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function NewsArticle({ node }) {
    
    return (
        <Link className="relative" to={`/news/${node.title.replace(/\s+/g, '-').toLowerCase()}`}>
            <article className="overflow-hidden rounded-2xl shadow-xl h-full">
                <div className="w-full m-auto">
                    <GatsbyImage className="w-full" image={getImage(node.image)} alt={node.title} />
                </div>
                <div className="flex flex-col px-8 pb-8">
                    <p className="text-sm mt-4 line-clamp-5">
                        {node.date}
                    </p>
                    <p className="text-base font-bold mt-2 line-clamp-5">
                        {node.title}
                    </p>
                    <p className="text-sm line-clamp-5">
                        {node.text}
                    </p>
                </div>
            </article>
        </Link>
    )
}
