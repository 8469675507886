import React, { useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"
import "swiper/css/pagination"

// import Swiper core and required modules
import SwiperCore, {
    Navigation, Pagination, Mousewheel, Keyboard
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Mousewheel, Keyboard]);

export default function FourCustomCarousel({ slides, fit = 'contain' }) {
    const [allowSlide, setAllowSlide] = useState(slides.length === 1 ? false : true)

    return (
        <>
            <div className='swiper-container'>
                <Swiper cssMode={false}
                    navigation={{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }}
                    loop={true}
                    simulateTouch={true}
                    className="swiper"
                    nextEl={'.swiper-button-next-unique'}
                    prevEl={'.swiper-button-prev-unique'}
                    pagination={{ el: '.swiper-pagination' }}
                    allowSlideNext={allowSlide}
                    allowSlidePrev={allowSlide}
                    initialSlide="0"
                    spaceBetween={50}
                    breakpoints={{
                        // when window width is >= 320px
                        640: {
                            slidesPerView: 2,
                        },
                        // when window width is >= 480px
                        1024: {
                            slidesPerView: 3,
                        },
                        // when window width is >= 640px
                        1280: {
                            slidesPerView: 4,
                        }
                    }}
                >
                    {slides.map(slide => (<SwiperSlide className={fit}>{slide}</SwiperSlide>))}

                </Swiper>
                {allowSlide && (
                    <div>
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                    </div>
                )}

                <div className="swiper-pagination"></div>
            </div>
        </>
    )
}