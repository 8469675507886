import React from 'react'

export default function Heropage() {
    return (
        <div>
            <main className="mt-16 lg:mt-32 xl:mt-40">
					<div className="sm:text-center lg:text-left">
						<h1 className="text-4xl tracking-tight font-bold text-white md:text-6xl">
							<span className="block xl:inline">«Консервний завод»</span>{' '}
							<span className="block"> ТМ Дем’янович</span>{' '}
							<span className="block"> ТМ Покровська</span>
						</h1>
						<p className="mt-3 text-base font-light text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
							Виробник бакалійної продукції під власними торговими марками
						</p>
					</div>
				</main>
        </div>
    )
}
