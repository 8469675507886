import React, { useState, useEffect } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PaperClipIcon } from '@heroicons/react/outline'
import { PhoneInput } from "@ua-opendata/react-phone-input"

const formNavigation = [
    { name: 'Питання', key: 18 },
    { name: 'Вакансії', key: 19 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


export default function Form() {
    const [formState, setFormState] = useState({
        name: "",
        select: "",
        email: "",
        tel: "",
        file: "",
        textarea: ""
    })

    const [formType, setFormType] = useState('Питання')

    const handleChange = (e) => {

        setFormState({
            ...formState,
            [e.target.name]: e.target.value,
        })
    }

    const handleAttachment = (e) => {
        setFormState({
            ...formState,
            [e.target.name]: e.target.files[0]
        });
    };

    const handleChangeSelect = (e) => {
        setFormState({
            ...formState,
            select: e.target.value,
        })
    }

    const [isInvalid, setIsInvalid] = useState(false)

    useEffect(() => {
        if (isInvalid) {
            const timer = setTimeout(() => setIsInvalid(false), 3000);
            return () => clearTimeout(timer);
        }

    }, [isInvalid]);

    return (
        <div className="mt-8 lg:mt-20">
            <h1 className="text-4xl text-center tracking-tight font-bold sm:text-5xl md:text-6xl md:text-left">
                Зворотній зв'язок
            </h1>
            <div className="grid grid-flow-row mx-auto md:mx-0 justify-items-center sm:grid-cols-3 lg:grid-flow-col gap-6 my-12 max-w-lg">
                {formNavigation.map((item) => (
                    <div key={item.name} onClick={() => setFormType(item.name)} className={classNames(formType === item.name ? "text-white bg-yellow-400 border-yellow-400 " : "bg-transparent ", "w-1/2 sm:w-full text-center border border-black text-lg py-1.5 rounded-lg hover:text-white hover:bg-yellow-400 hover:border-yellow-400")}>
                        {item.name}
                    </div>
                ))}
            </div>
            <div className="mt-8 lg:mt-12 px-8 py-12 shadow-2xl rounded-2xl">
                {formType === 'Вакансії' ? (
                    <form
                        name="Вакансії"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        enctype='multipart/form-data'
                        action="/success/"
                    >
                        <input type="hidden" name="form-name" value="Вакансії" />
                        <label className="hidden">
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={handleChange} />
                        </label>
                        <div className="grid gap-12 grid-cols-1 md:grid-cols-2">
                            <div>
                                <div className="flex flex-col">

                                    <StaticQuery
                                        query={
                                            graphql`
                                            {
                                                allDataJson {
                                                    nodes {
                                                        vacancies {
                                                            vacancy
                                                        }
                                                    }
                                                }
                                            }
                                            `
                                        }
                                        render={data => {
                                            const dataArr = data.allDataJson.nodes[1].vacancies ? data.allDataJson.nodes[1].vacancies : data.allDataJson.nodes[0].vacancies ? data.allDataJson.nodes[0].vacancies : data.allDataJson.nodes[2].vacancies

                                            return (
                                                <select onChange={handleChangeSelect} id="select" className="form-select w-100 mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800  font-semibold focus:border-yellow-400 focus:outline-none">
                                                    <option disabled="" selected="" className="font-semibold">Вакансії</option>
                                                    {dataArr.map(item => <option className="font-semibold">{item.vacancy}</option>)}
                                                </select>
                                            )
                                        }}
                                    />

                                </div>
                                <div className="flex flex-col mt-2">
                                    <label htmlFor="name" className="hidden">ПІБ</label>
                                    <input
                                        onChange={handleChange}
                                        type="name"
                                        name="name"
                                        id="name"
                                        placeholder="ПІБ"
                                        className="w-full py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                    />
                                </div>
                                <label htmlFor="select" className="hidden">Вакансія</label>
                                <input
                                    onChange={handleChange}
                                    type="select"
                                    name="select"
                                    id="select"
                                    className="hidden"
                                    value={formState.select}
                                />

                                <div className="flex flex-col mt-2">
                                    <label htmlFor="email" className="hidden">Email</label>
                                    <input
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                    />
                                </div>

                                <div className="flex flex-col mt-2">
                                    <label htmlFor="tel" className="hidden">Телефон</label>
                                    <PhoneInput
                                        onChange={handleChange}
                                        type="tel"
                                        name="tel"
                                        id="tel"
                                        placeholder="Телефон"
                                        className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                    />
                                </div>
                                <div className="flex flex-col mt-4">
                                    <div className="flex flex-row">
                                        <PaperClipIcon className="text-gray-400 mr-2 w-5 h-5 mb-2.5" />
                                        <label htmlFor="file" className="text-gray-400 font-semibold">Прикріпіть файл<input className="hidden" type="file" name="file" id="file" onChange={handleAttachment} /></label>
                                    </div>
                                    <div className="flex items-center mb-2.5">
                                        <input name="confidentiality" id="confidentiality" onInvalid={(e) => setIsInvalid('Ви не погодилися з «Політикою конфіденційності»')} type="checkbox" className="form-checkbox h-5 w-5 mr-2 border-gray-400 border-2 focus:border-gray-400 rounded-md text-gray-400" required />
                                        <span className=" text-gray-400 font-semibold">Я погоджуюся з «Політикою конфіденційності»</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input name="user-agreement" id="user-agreement" onInvalid={(e) => setIsInvalid('Ви не погодилися з «Угодою користувача»')} type="checkbox" className="form-checkbox h-5 w-5 mr-2 border-gray-400 border-2 focus:border-gray-400 rounded-md text-gray-400" required />
                                        <span className=" text-gray-400 font-semibold">Я погоджуюся з «Угодою користувача»</span>
                                    </div>
                                    {isInvalid ? <span className="mt-4 text-red-400 font-semibold">{isInvalid}</span> : null}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="textarea" className="hidden">Повідомлення</label>
                                <textarea
                                    onChange={handleChange}
                                    type="name"
                                    name="textarea"
                                    id="textarea"
                                    placeholder="Повідомлення"
                                    className="w-full h-full py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full bg-yellow-400 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-yellow-500 transition ease-in-out duration-300"
                                >
                                    Надіслати повідомлення
                                </button>
                            </div>
                        </div>
                    </form>
                ) : (
                    <form
                        name="Питання"
                        method="post"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        enctype='multipart/form-data'
                        action="/success/"
                    >
                        <input type="hidden" name="form-name" value="Питання" />
                        <label className="hidden">
                            Don’t fill this out:{" "}
                            <input name="bot-field" onChange={handleChange} />
                        </label>
                        <div className="grid gap-12 grid-cols-1 md:grid-cols-2">
                            <div>
                                <div className="flex flex-col mt-2">
                                    <label htmlFor="name" className="hidden">ПІБ</label>
                                    <input
                                        onChange={handleChange}
                                        type="name"
                                        name="name"
                                        id="name"
                                        placeholder="ПІБ"
                                        className="w-full py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                    />
                                </div>
                                <div className="flex flex-col mt-2">
                                    <label htmlFor="email" className="hidden">Email</label>
                                    <input
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        className="invalid:border-red-400 w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                        onError={() => alert('Email error')}

                                    />
                                </div>

                                <div className="flex flex-col mt-2">
                                    <label htmlFor="tel" className="hidden">Телефон</label>
                                    <PhoneInput
                                        onChange={handleChange}
                                        type="tel"
                                        name="tel"
                                        id="tel"
                                        placeholder="Телефон"
                                        className="invalid:border-red-400 w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                    />
                                </div>
                                <div className="flex flex-col mt-4">
                                    <div className="flex flex-row mb-2.5">
                                        <PaperClipIcon className="text-gray-400 w-5 h-5 mr-2" />
                                        <label htmlFor="file" className="text-gray-400 font-semibold">Прикріпіть файл<input className="hidden" type="file" name="file" id="file" onChange={handleAttachment} /></label>
                                    </div>
                                    <div className="flex items-center mb-2.5">
                                        <input name="confidentiality" id="confidentiality" onInvalid={(e) => setIsInvalid('Ви не погодилися з «Політикою конфіденційності»')} type="checkbox" className="form-checkbox h-5 w-5 mr-2 border-gray-400 border-2 focus:border-gray-400 rounded-md text-gray-400" required />
                                        <span className=" text-gray-400 font-semibold">Я погоджуюся з «Політикою конфіденційності»</span>
                                    </div>
                                    <div className="flex items-center">
                                        <input name="user-agreement" id="user-agreement" onInvalid={(e) => setIsInvalid('Ви не погодилися з «Угодою користувача»')} type="checkbox" className="form-checkbox h-5 w-5 mr-2 border-gray-400 border-2 focus:border-gray-400 rounded-md text-gray-400" required />
                                        <span className=" text-gray-400 font-semibold">Я погоджуюся з «Угодою користувача»</span>
                                    </div>
                                    {isInvalid ? <span className="mt-4 text-red-400 font-semibold">{isInvalid}</span> : null}
                                </div>
                            </div>
                            <div>
                                <label htmlFor="textarea" className="hidden">Повідомлення</label>
                                <textarea
                                    onChange={handleChange}
                                    type="name"
                                    name="textarea"
                                    id="textarea"
                                    placeholder="Повідомлення"
                                    className="w-full h-full py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-yellow-400 focus:outline-none"
                                />
                            </div>
                            <div>
                                <button
                                    type="submit"
                                    className="w-full bg-yellow-400 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-yellow-500 transition ease-in-out duration-300"
                                >
                                    Надіслати повідомлення
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}
