import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import FourCustomCarousel from './4carousel'
import CustomCarousel from './carousel'

const aboutNavigation = [
    { name: 'Історія', key: 1 },
    { name: 'Нагороди', key: 2 },
    { name: 'Переваги', key: 3 },
]

const images = [
    { name: 'diplomas/1.jpg', key: 4 },
    { name: 'diplomas/2.jpg', key: 5 },
    { name: 'diplomas/3.jpg', key: 6 },
    { name: 'diplomas/4.jpg', key: 7 },
    { name: 'diplomas/5.jpg', key: 8 },
    { name: 'diplomas/6.jpg', key: 9 },
    { name: 'diplomas/7.jpg', key: 10 },
    { name: 'diplomas/8.jpg', key: 11 },
    { name: 'diplomas/9.jpg', key: 12 },
    { name: 'diplomas/10.jpg', key: 13 },
]

const slides = [
    { name: 'bg.webp', key: 14 },
    { name: 'bg.webp', key: 15 },
    { name: 'bg.webp', key: 16 },
    { name: 'bg.webp', key: 17 }
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const historyBlock = (
    <div className="container mx-auto flex mt-8 lg:mt-12 md:flex-row flex-col items-center">
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 md:mb-0">
            <CustomCarousel slides={slides.map(item => <img key={item.key} src={item.name} alt="Історія заводу ТМ Дем'янович" />)} />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 pt-12 md:pt-0 flex flex-col md:items-start md:text-left items-center text-center" bis_skin_checked="1">
            <h2 className="text-3xl sm:text-4xl md:text-5xl mb-4 font-semibold text-gray-900">Історія заводу</h2>
            <p className="leading-relaxed">
                Георгій Дем’янович каже: «Я виробляю таку продукцію, яку можу спокійно давати власним дітям та онукам», а його діти та онуки підтверджують, що продукція ТМ Дем’янович не просто якісна, а й дуже смачна.
                «Херсонський підприємець Червєнков Георгій Дем’янович свою трудову діяльність розпочав ще у 1986 році на Херсонському машинобудівному заводі ім. Петровського, де працював слюсарем-бригадиром механозбірних робіт. Після цього працював у сфері оптової та роздрібної торгівлі.
                У 2002 році він заснував власну компанію, яка займалася оптовою торгівлею консервації, бакалійної продукції під власною торговою маркою «Дем'янович», виробництвом продукції під індивідуальні замовлення, а також торгівлею широким асортиментом склотари для промислового виробництва та побутових потреб.
                У розвиток бізнесу молодим підприємцем було вкладено багато зусиль та любові.  Георгій Дем’янович використовує свої унікальні знання для відбору найкращої продукції.
            </p>
            <Link className="relative" to={`history`}><div className="mt-4 px-12 py-1.5 rounded-full text-white bg-yellow-400">Більше</div></Link>
        </div>
    </div>
)

const advantagesBlock = (
    <div className="container mx-auto flex mt-8 lg:mt-12 flex-col">
        <h2 className="text-3xl sm:text-4xl md:text-5xl mb-4 font-semibold text-gray-900">
            Наші переваги
        </h2>
        <p className="leading-relaxed">
            <p className="font-bold">
                Основне завдання підприємства: «Забезпечити людей смачною та корисною сільськогосподарською продукцією».
            </p>
            <p className="mt-4">
                <span className="font-bold">Розташування – </span>
                постачальниками сировини для продукції є українські фермери. Завод розташовано в Херсонській області - одній з національних лідерів у вирощуванні овочів і баштанних культур. Серед овочевих культур в Херсонській області найбільше значення мають помідори, капуста, цибуля, перець солодкий, баклажани. Також Херсонщина славиться фруктовими садами, де вирощуються соковиті абрикоси, персики, вишні, яблука та сливи. Агропідприємства Херсонської області виробляють високоякісну продукцію, що забезпечує свіжість сировини для продукції.
            </p>
            <p className="mt-4">
                <span className="font-bold">Скло тара – </span>
                більша частина продукції виготовляється з екологічної скло тари. Переваги скла відомі з давніх-давен і цінуються донині. Саме скло можна переробляти  нескінченно довго. Скло виготовляється  з розплавленого кварцу, тому навіть якщо теоретично воно не розкладається, воно з часом розпадеться на пісочкоподібні частинки, тож вони якось повернуться «до природи». Однак, скло варто переробляти, оскільки ресурси піску постійно скорочуються, тому вторинне використання розумніше. Основною перевагою скла як пакувального матеріалу є його хімічна інертність. Це означає, що воно не реагує з речовиною, з якою зараз контактує. В результаті це не змінює колір або смак страв, укладених у скляну тару.
            </p>
            <p className="mt-4">
                <span className="font-bold">Індивідуальний підхід – </span>
                Дем’янович має індивідуальний підхід до кожного споживача та замовника, завод виготовляє продукцію як під ТМ Дем’янович, так і під індивідуальні замовлення партнерів. 
            </p>
            <p className="mt-4">
                <span className="font-bold">Якість продукції – </span>
                для виробництва продукції впровадженно систему харчової безпеки НАССР, щоденно здійснюється контроль сировини, матеріалів упаковки, доставки готової продукції споживачам. Постачальниками сільськогосподарської продукції для заводу є найкращі українські фермери. 
            </p>
            <p className="mt-4">
                <span className="font-bold">Досвід - </span>
                виготовлення продукції ТМ Дем’янович давно стало сімейною справою. Більше 19 років Дем’янович особисто контролює виробництво та щодня бореться за якість продукції та застосування найкращих технологій у виробництві.  
            </p>
        </p>
    </div>
)

const diplomasBlock = (
    <div class="container mx-auto flex mt-8 lg:mt-12 md:flex-row flex-col items-center ">
        <div className="lg:w-full w-5/6 md:mb-0 mx-auto">
            <FourCustomCarousel slides={images.map(item => <img key={item.key} src={item.name} />)} />
        </div>
    </div>
)

export default function Aboutus({aboutType, setAboutType}) {
    const [renderedComponent, setRenderComponent] = useState(historyBlock)

    const renderComponent = () => {
        if (aboutType === 'Нагороди') {
            setRenderComponent(diplomasBlock)
        } else if (aboutType === 'Переваги') {
            setRenderComponent(advantagesBlock)
        } else {
            setRenderComponent(historyBlock)
        }
    }

    useEffect(() => {
        renderComponent()
    }, [aboutType])


    return (
        <div>
            <div className='mt-8 lg:mt-12'>
                <h1 className="text-4xl text-center tracking-tight font-bold sm:text-5xl md:text-6xl md:text-left">
                    Про нас
                </h1>
                <div className="grid grid-flow-row mx-auto md:mx-0 justify-items-center sm:grid-cols-3 lg:grid-flow-col gap-6 my-12 max-w-lg">
                    {aboutNavigation.map((item) => (
                        <div key={item.name} onClick={() => setAboutType(item.name)} className={classNames(aboutType === item.name ? "text-white bg-yellow-400 border-yellow-400 " : "bg-transparent ", "w-1/2 sm:w-full text-center border border-black text-lg py-1.5 rounded-lg hover:text-white hover:bg-yellow-400 hover:border-yellow-400")}>
                            {item.name}
                        </div>
                    ))}
                </div>
                {renderedComponent}
            </div>
        </div>
    )
}
