import React from 'react'
import NewsArticle from './newsArticle'
import { graphql, StaticQuery, Link } from 'gatsby'

export default function News() {
    return (
        <div className="mt-8 lg:mt-24">
            <h1 className="text-4xl text-center text-gray-900 tracking-tight font-bold sm:text-5xl md:text-6xl md:text-left">
                Новини
            </h1>
            <div className="my-12">
                <div className="relative">
                    <StaticQuery
                        query={
                            graphql`
                            {
                                allDataJson {
                                    nodes {
                                        siteNews {
                                            date
                                            title
                                            text    
                                            image {
                                                childImageSharp {
                                                  gatsbyImageData(
                                                    height: 240
                                                    quality: 70
                                                    transformOptions: {fit: COVER}
                                                    placeholder: DOMINANT_COLOR
                                                  )
                                                }
                                            }
                                            video
                                        }
                                    }
                                }
                            }
                            `
                        }
                        render={data => {
                            const filteredData = data.allDataJson.nodes[1].siteNews ? data.allDataJson.nodes[1].siteNews : data.allDataJson.nodes[0].siteNews ? data.allDataJson.nodes[0].siteNews : data.allDataJson.nodes[2].siteNews

                            return (
                                <div className="mb-12">
                                    {/* cols-4 */}
                                    <div className="block mx-auto max-w-md sm:max-w-none">
                                        <div className=" grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                                            {filteredData.slice(0, 4).map(item => <NewsArticle node={item} />)}
                                        </div>
                                    </div>
                                    <div className="flex justify-center"><Link className="relative" to={`news`}><div className="mt-4 px-12 py-1.5 rounded-full text-white bg-yellow-400">Всі новини</div></Link></div>
                                </div>
                            )
                        }
                        }
                    />
                </div>
            </div>
        </div>
    )
}
