import React, { useState } from "react"
import Container from "../components/container"
import Navbar from "../components/navbar"
import Heropage from "../components/heropage"
import Aboutus from "../components/aboutus"
import Products from "../components/products"
import News from "../components/news"
import Form from "../components/form"
import Footer from "../components/footer"
import { Helmet } from "react-helmet";

export default function Home() {
	const [type, setType] = useState('Всі')
	const [aboutType, setAboutType] = useState('Історія')

	const navigation = [
		{ name: 'Головна', href: '/', key: 35 },
		{
			name: 'Про нас', href: '#aboutus', key: 36, children: [
				{ name: 'Історія', href: '#', key: 37 },
				{ name: 'Нагороди', href: '#', key: 38 },
				{ name: 'Переваги', href: '#', key: 39 },
			]
		},
		{
			name: 'Продукція', href: '#products', key: 40, children: [
				{ name: 'Тушонка', href: '#', key: 41 },
				{ name: 'Консерви', href: '#', key: 42 },
				{ name: 'Соки', href: '#', key: 43 },
				{ name: 'Крупи', href: '#', key: 44 },
				{ name: 'Олія', href: '#', key: 45 },
				{ name: 'Склотара', href: '#', key: 46 },
			]
		},
		{ name: 'Новини', href: '#news', key: 47 },
		{ name: "Зв'язатися", href: '#vacancies', key: 48 },
		{ name: 'Контакти', href: '#contacts', key: 49 },
	]

	return (
		<div className="overflow-hidden">
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="description" content="Виробник найкращої бакалійної продукції під власною торговою маркою «Дем'янович»" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta name="image" content="bg.jpg" />
				<meta name="theme-color" content="#53C82A" />
				<link rel="apple-touch-icon" href="logo.png"></link>
				<title>Дем'янович</title>
			</Helmet>
			<div id="home" className="relative bg-hero-mobile-image md:bg-hero-image bg-no-repeat bg-cover bg-center h-hero-screen overflow-hidden">
				<Container>
					<Navbar setType={setType} setAboutType={setAboutType} navigation={navigation} />
					<Heropage />
				</Container>
			</div>
			<div id="aboutus" >
				<Container>
					<Aboutus aboutType={aboutType} setAboutType={setAboutType} />
				</Container>
			</div>
			<div id="products">
				<Container>
					<Products type={type} setType={setType} />
				</Container>
			</div>
			<div id="news">
				<Container>
					<News />
				</Container>
			</div>
			<div id="vacancies">
				<Container>
					<Form />
				</Container>
			</div>
			<div className="bg-green-400" id="contacts">
				<Container>
					<Footer />
				</Container>
			</div>
		</div>
	)
}
