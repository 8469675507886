import React, { useEffect, useState } from 'react'
import Article from './article'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { graphql, StaticQuery } from 'gatsby'

const products = [
    { name: 'Всі', key: 20 },
    { name: 'Тушонка', key: 21 },
    { name: 'Консерви', key: 22 },
    { name: 'Соки', key: 23 },
    { name: 'Крупи', key: 24 },
    { name: 'Олія', key: 25 },
    { name: 'Склотара', key: 26 },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Products({ type, setType }) {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const productsOpen = sessionStorage.getItem('productsOpen')
            if (productsOpen === 'true') {
                setOpen(true)
            }
        }
    }, [])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            sessionStorage.setItem('productsOpen', open)
        }
    }, [open])

    return (
        <div className="mt-8 lg:mt-24">
            <h1 className="text-4xl text-center text-yellow-400 tracking-tight font-bold sm:text-5xl md:text-6xl md:text-left">
                Продукція
            </h1>
            <div className="grid grid-flow-row auto-cols-fr justify-items-center sm:grid-cols-3 lg:grid-flow-col gap-6 my-12 ">
                {products.map((item) => (
                    <div key={item.name} onClick={() => setType(item.name)} className={classNames(type === item.name ? "text-white bg-yellow-400 border-yellow-400 " : "bg-transparent ", "w-1/2 sm:w-full text-center border border-black text-lg py-1.5 rounded-lg hover:text-white hover:bg-yellow-400 hover:border-yellow-400")}>
                        {item.name}
                    </div>
                ))}
            </div>
            <div className="my-12">
                <div className="relative">
                    <StaticQuery
                        query={
                            graphql`
                            {
                                allDataJson {
                                    nodes {
                                      productList {
                                        slug
                                        type
                                        title
                                        composition
                                        weight
                                        images {
                                          image {
                                            childImageSharp {
                                              gatsbyImageData(
                                                height: 300
                                                transformOptions: {fit: COVER}
                                                placeholder: DOMINANT_COLOR
                                              )
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                            }
                            `
                        }
                        render={data => {
                            const filteredData = data.allDataJson.nodes[0].productList ? data.allDataJson.nodes[0].productList.filter(item => item.type === type || type === 'Всі') : data.allDataJson.nodes[1].productList ? data.allDataJson.nodes[1].productList.filter(item => item.type === type || type === 'Всі') : data.allDataJson.nodes[2].productList.filter(item => item.type === type || type === 'Всі')

                            if (filteredData.length > 9) {
                                return (
                                    <div>
                                        {/* cols-5 */}
                                        <div className="hidden xl:block">
                                            <div className="grid gap-12 lg:grid-cols-5 mb-12">
                                                {filteredData.slice(0, 10).map((item, index) => <Article key={index} className="h-full" node={item} />)}
                                            </div>

                                            <div className="relative flex justify-center pb-20">
                                                {!open ? (
                                                    <div className="relative">
                                                        <div className="absolute w-full h-full bg-gradient-to-t from-white z-10"/>
                                                        <div className="grid gap-12 lg:grid-cols-5 h-40 overflow-hidden">
                                                            {filteredData.slice(10, 15).map((item, index) => <Article key={index} node={item} />)}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="grid gap-12 lg:grid-cols-5">
                                                        {filteredData.slice(10).map((item, index) => <Article key={index} node={item} />)}
                                                    </div>)}

                                                <div
                                                    onClick={handleOpen}
                                                    className={classNames(
                                                        open ? 'bottom-0' : 'block top-12', 'absolute z-20')}
                                                >
                                                    {!open ? (<ChevronDownIcon
                                                        className='rounded-full bg-green-400 h-12 w-12 text-white m-auto'
                                                        aria-hidden="true"
                                                    />) : <ChevronUpIcon
                                                        className='rounded-full bg-green-400 h-12 w-12 text-white m-auto'
                                                        aria-hidden="true"
                                                    />}
                                                </div>
                                            </div>
                                        </div>

                                        {/* cols-4 */}
                                        <div className="block mx-auto xl:hidden max-w-md sm:max-w-none">
                                            <div className=" grid gap-12 sm:grid-cols-2 lg:grid-cols-4 mb-12">
                                                {filteredData.slice(0, 8).map((item, index) => <Article key={index} node={item} />)}
                                            </div>

                                            <div className="relative flex justify-center pb-20">
                                                {!open ? (
                                                    <div className="relative">
                                                        <div className="absolute w-full h-full bg-gradient-to-t from-white z-10"/>
                                                        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4 h-40 overflow-hidden opacity-40">
                                                            {filteredData.slice(8, 12).map((item, index) => <Article key={index} node={item} />)}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                                                        {filteredData.slice(12).map((item, index) => <Article key={index} node={item} />)}
                                                    </div>)}

                                                <div
                                                    onClick={handleOpen}
                                                    className={classNames(
                                                        open ? 'bottom-0' : 'block top-12', 'absolute z-20')}
                                                >
                                                    {!open ? (<ChevronDownIcon
                                                        className='rounded-full bg-green-400 h-12 w-12 text-white m-auto'
                                                        aria-hidden="true"
                                                    />) : <ChevronUpIcon
                                                        className='rounded-full bg-green-400 h-12 w-12 text-white m-auto'
                                                        aria-hidden="true"
                                                    />}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div>
                                        <div className="grid gap-12 lg:grid-cols-5 mb-12">
                                            {filteredData.map((item, index) => <Article key={index} node={item} />)}
                                        </div>
                                    </div>
                                )
                            }
                        }
                        }
                    />
                </div>
            </div>
        </div>
    )
}
