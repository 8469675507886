import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function Article({ node }) {
    return (
        <Link className="relative" to={`/products/${node.title.replace(/\s+/g, '-').toLowerCase()}`}>
            <article className="overflow-hidden rounded-2xl shadow-xl h-full">
                <div className="w-1/2 lg:w-full m-auto">
                    <GatsbyImage className="w-full" image={getImage(node.images[0].image)} alt={node.title} />
                </div>
                <div className="flex flex-col px-8 pb-8">
                    <p className="font-bold text-lg leading-5">
                        {node.title}
                    </p>
                    <p className="text-sm my-4 line-clamp-5">
                        Склад: {node.composition}
                    </p>
                    <p className="text-sm">
                        Об'єм/вага: {node.weight}
                    </p>
                </div>
            </article>
        </Link>
    )
}
